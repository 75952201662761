export default {
  "slide": "_slide_na2uy_1",
  "old": "_old_na2uy_10",
  "footer": "_footer_na2uy_21",
  "imageWrapper": "_imageWrapper_na2uy_35",
  "detail": "_detail_na2uy_40",
  "button": "_button_na2uy_45",
  "left": "_left_na2uy_64",
  "right": "_right_na2uy_67",
  "editBar": "_editBar_na2uy_70"
};import "ni:sha-256;Uj5Wmow_9nR1Pxg2xxBU0ofm7o_pQPNK6D_5yJ7kD-w";