export default {
  "body": "_body_jvnom_1",
  "head": "_head_jvnom_4",
  "url": "_url_jvnom_7",
  "urlCell": "_urlCell_jvnom_15",
  "copy": "_copy_jvnom_18",
  "newLink": "_newLink_jvnom_42",
  "buttons": "_buttons_jvnom_46",
  "access": "_access_jvnom_63",
  "table": "_table_jvnom_66"
};import "ni:sha-256;JfZPwDkMxvnWuC5nWntdlAEgL8eBt4CFlehCpLAURyk";