export default {
  "body": "_body_plwrm_1",
  "init": "_init_plwrm_4",
  "selectSize": "_selectSize_plwrm_11",
  "progress": "_progress_plwrm_18",
  "progressBar": "_progressBar_plwrm_22",
  "done": "_done_plwrm_29",
  "todo": "_todo_plwrm_33",
  "link": "_link_plwrm_37",
  "text": "_text_plwrm_45",
  "success": "_success_plwrm_48"
};import "ni:sha-256;5WWpq-U-PszsSC6xsfNr_Yb4c-25mwOWeq41u6gIf8U";